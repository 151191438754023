.image-image {
    width: 100%;
    height: auto;
    opacity: 0.8;
}
/* Container holding the image and the text */
.image-container {
    position: relative;
  }
  
/* Bottom right text */
.image-text {
position: absolute;
top: 1%;
background-color: transparent;
color: black;
padding-left: 10%;
padding-right: 10%;
width: 100%;
}

.pricing-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-auto-rows: auto;
}

.who-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-auto-rows: auto;
}

.what-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-auto-rows: auto;
}

.how-grid {
    display: grid;
    grid-template-columns: 5fr 1fr 4fr 1fr 5fr;
    grid-auto-flow: row;
    grid-auto-rows: auto;
}

.input-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: auto; 
    width: -webkit-fill-available;
}

.usecase-orga-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-auto-rows: auto; 
}

.pricing-grid-events {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-auto-rows: auto;
}

.logo-image {
    max-width: 20%;
}

.how-image{
    max-width: 80%;
}

/* css for small screens */
@media (max-width: 900px) {
    .what-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .connector {
        padding-bottom: 20px !important;
    }

    .step {
        padding-bottom: 20px !important;
    }

    .logo-image {
        max-width: 35%;
    }

}

/* css for small screens */
@media (max-width: 600px) {    
    
    .logo-image {
        max-width: 50%;
    }

    .pricing-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .planContainer {
        justify-content: space-evenly;
        text-align: center;
    }

    .who-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .pricing-grid-events {
        display: grid;
        grid-template-columns: 1fr;
    }

    .what-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .how-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .input-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        
    }

    .usecase-orga-grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .infoBox {
        padding-left: 8% !important;
        padding-right: 8% !important;
    }

    .one {
        grid-row: 1;
        justify-content: center;
    }

    .two {
        grid-row: 2;
    }

    .three {
        grid-row: 3;
    }

    .four {
        grid-row: 4;
    }

    .five {
        grid-row: 5;
    }

    .six {
        grid-row: 6;
    }

    .seven {
        grid-row: 7;
    }

    .eight {
        grid-row: 8;
    }

    .nine {
        grid-row: 9;
    }

    .ten {
        grid-row: 10;
    }

    .eleven {
        grid-row: 11;
    }

    .twelve {
        grid-row: 12;
    }

    .thirteen {
        grid-row: 13;
    }

    .fourteen {
        grid-row: 14;
    }

    .fivteen {
        grid-row: 15;
    }

    .sixteen {
        grid-row: 16;
        display: none;
    }
    .sixteen-2 {
        grid-row: 16;
    }

    .planBorder {
        border-right-style: solid;
    }

    #basic-checkbox:checked ~ .planHeading:after {
        content: "-";
        font-size: 36px;
        color: #151b26;
        transform: translateX(-180%);
    }

    #uc1-checkbox:checked ~ .uc1H.whoHeading:after {
        content: "-";
        font-size: 36px;
        color: #151b26;
        transform: translateX(-180%);
    }

    #uc1-checkbox:not(:checked) ~ .uc1 {
        display: none;
    }

    #uc2-checkbox:checked ~ .uc2H.whoHeading:after {
        content: "-";
        font-size: 36px;
        color: #151b26;
        transform: translateX(-180%);
    }

    #uc2-checkbox:not(:checked) ~ .uc2 {
        display: none;
    }

    #uc3-checkbox:checked ~ .uc3H.whoHeading:after {
        content: "-";
        font-size: 36px;
        color: #151b26;
        transform: translateX(-180%);
    }

    #uc3-checkbox:not(:checked) ~ .uc3 {
        display: none;
    }

    #uc4-checkbox:checked ~ .u4H.whoHeading:after {
        content: "-";
        font-size: 36px;
        color: #151b26;
        transform: translateX(-180%);
    }

    #uc4-checkbox:not(:checked) ~ .uc4 {
        display: none;
    }

    #basic-checkbox:not(:checked) ~ .basicInfo {
        display: none;
    }

    #premium-checkbox:not(:checked) ~ .premiumInfo {
        display: none;
    }

    #business-checkbox:not(:checked) ~ .businessInfo {
        display: none;
    }

    #enterprise-checkbox:not(:checked) ~ .enterpriseInfo {
        display: none;
    }

    #premium-checkbox:checked ~ .planHeading:after {
        content: "-";
        font-size: 36px;
        color: #151b26;
        transform: translateX(-180%);
    }

    #business-checkbox:checked ~ .planHeading:after {
        content: "-";
        font-size: 36px;
        color: #151b26;
        transform: translateX(-180%);
    }

    #enterprise-checkbox:checked ~ .planHeading:after {
        content: "-";
        font-size: 36px;
        color: #151b26;
        transform: translateX(-180%);
    }

    #enterprise-checkbox:not(:checked) ~ .planBorder {
        border-bottom-style: solid;
    }

    .planHeading:after {
        content: "+";
        font-size: 36px;
        font-weight: 300;
        color: #b7bfc6;
        line-height: 36px;
        position: absolute;
        right: 0; 
        transform: translateX(-170%);
        font-size: xx-large;
    }

    .whoHeading:after {
        content: "+";
        font-size: 36px;
        font-weight: 300;
        color: #b7bfc6;
        line-height: 36px;
        position: absolute;
        right: 0; 
        transform: translateX(-170%);
        font-size: xx-large;
    }

    .whoTitle {
        margin-right: 0px !important;
    }

    .usecaseTitle {
        margin-right: 0px !important;
    }

    .usecaseText {
        margin-right: 0px !important;
    }

    .usecaseBottom {
        margin-right: 0px !important;
    }

    .whoText {
        margin-right: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }

    .whoIcon {
        margin-right: 0px !important; 
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }

    .whoHeading {
        margin-right: 0px !important;
    }

    .whoButton {
        margin-right: 0px !important;
    }

    .connector {
        padding-top: 30px;
        padding-bottom: 30px !important;
    }

    .connector:after {
        content: "\2193" !important;
        position: absolute;
        transform: translateX(160%) translateY(-50%);
    }

    .how-connector {
        transform: rotate(90deg) translateX(50%) !important;
    }
    .one-input {
        grid-row: 1 !important;
        grid-column: 1 !important;
    }
    
    .two-input {
        grid-row: 2 !important;
        grid-column: 1 !important;
    }
    
    .three-input {
        grid-row: 3 !important;
        grid-column: 1 !important;
    }
    
    .four-input {
        grid-row: 1 !important;
        grid-column: 2 !important;
    }
    
    .five-input {
        grid-row: 2 !important;
        grid-column: 2 !important;
    }
    
    .six-input {
        grid-row: 3 !important;
        grid-column: 2 !important;
    }
    
    .seven-input {
        grid-row: 1 !important;
        grid-column: 3 !important;
    }
    
    .eight-input {
        grid-row: 2 !important;
        grid-column: 3 !important;
    }
    
    .nine-input {
        grid-row: 3 !important;
        grid-column: 3 !important;
    }

    .main-input {
        font-size: 15px !important;
        font-weight: bold !important;
        padding-top: 0px !important;
        padding-bottom: 10%;
    }

    .list-input {
        font-size: 14px !important;
        padding-left: 0px !important;
        width: 80% !important;
    }

    .how-output {
        text-align: center;
        padding-top: 20px;
        transform: translateY(0%) !important;
    }

}

.checkbox-invisible{
    display: none;
}

.contentContainer {
    margin: auto;
    max-width: 1312px;
}

.infoBoxes {
    padding-top: 10px;
    padding-bottom: 40px;
}

.infoBox {
    padding-bottom: 40px;
    padding-left: 3%;
    padding-right: 3%;
}

.infoBoxGray {
    background-color: lightgrey;
}

.infoBoxWhite {
    background-color: white;
    padding-bottom: 90px;
}

.infoBoxWhitePlans {
    background-color: white;
    padding-bottom: 60px;
    padding-top: 0px;
}

.infoBoxWhiteWorkflow {
    background-color: white;
    padding-bottom: 90px;
}

.infoBoxHeader {
    font-size: larger;
    margin-bottom: 50px;
    margin-left: 30px;
}

.infoBoxText {
    margin-bottom: 15px;
}

.infoBoxContent {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
}

.infoBoxImage {
    padding: 0px;
}

.infoBoxInfo {
    margin-left: 15px;
    width: 95%;
}

.stepContainer {
    justify-content: center;
}

.step {
    width: 150px;
    font-size: x-large;
    text-align: center;
}

.stepText {
    font-size: medium;
    padding-top: 10px;
}

.connector {
    padding-left: 14%;
}

.connector:after {
    content: "\2192";
    font-size: xx-large;
}

.button {
    width: 70px;
    padding-left: 30px;
}

.imgBw {
	filter: grayscale(1);
}



.planIncludesContainer {
    justify-content: space-evenly;
    text-align: left;
}

.planList {
    /*list-style-image: url("butterfly.png");*/
    list-style-type: none;
    margin-bottom: 5px;
    font-size: large;
}

.includedList {
    flex: 1;
    flex-grow: 6;
    padding-left: 10px;
}

.plan {
    padding: 30px;
}

.planThistle {
    background-color: thistle;
    padding: 30px;
}

.planBorder {
    padding: 30px;
    border-color: thistle;
    border-left-style: solid;
}

.planHeading {
    font-size: xxx-large;
    padding-bottom: 30px;
    font-weight: 200;
}

.planTitle {
    padding-top: 20px;
    flex: 1;
}

.planText {
    font-size: large;
    font-weight: lighter;
    padding-top: 20px;
    padding-bottom: 10px;
}

.planPrice {
    text-align: center;
    font-size: xx-large;
}

.planInfo {
    border-color: thistle;
    border-top-style: solid;
}

.planPriceInfoExtra {
    padding-bottom: 40px;
    font-size: small;
}

.planPriceInfo {
    padding-bottom: 40px;
    font-size: small;
}

.subscriptionIcon {
    flex-grow: 1;
    flex: 1;
}

.planPricing {
    padding-top: 20px;
    flex: 1;
}

.planCTA {
    padding-top: 20px;
    padding-bottom: 20px;
    flex: 1;
    border-color: thistle;
    border-bottom-style: solid;
}

.planCTAThistle {
    border-color: purple;
    border-bottom-style: solid;
}

.planIncluded {
    flex: 1;
    text-align: left;
}


.whoHeading {
    font-size: xx-large;
    font-weight: 200;
}

.whoTitle {
    padding-top: 20px;
    flex: 1;
    border-color: thistle;
    border-width: thin;
    border-left-style: solid;
    border-right-style: solid;
    border-top-style: solid;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin-right: 30px;
    min-width: 200px;
    max-width: 500px;
    margin-top: 20px;
}

.whoIcon {
    flex: 1;
    border-color: thistle;
    border-width: thin;
    border-left-style: solid;
    border-right-style: solid;
    font-size: xxx-large;
    padding-bottom: 30px;
    margin-right: 30px;
    min-width: 200px;
    max-width: 500px;
}


.whoText {
    flex: 1;
    border-color: thistle;
    border-width: thin;
    border-left-style: solid;
    border-right-style: solid;
    margin-right: 30px;
    display: block;
    min-width: 200px;
    max-width: 500px;
}

.whoButton {
    border-color: thistle;
    border-width: thin;
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    margin-right: 30px;
    min-width: 200px;
    max-width: 500px;
}

.whyContent {
    justify-content: center;
    align-items: center;
}

.whyImage {
    flex-grow: 1;
}

.whyText {
    flex-grow: 2;
    margin-left: 20px;
    margin-right: 20px;
    font-size: large;
    font-weight: lighter;
    text-align: justify;
}

.whyLabel {
    font-size: medium;
    font-weight: 500; 
}

.imgWhy {
    height: 230px;
}

.imgCard {
    height: 140;
}

.whyCard {
    height: 300;
}

.endCOA {
    justify-content: center;
}

.grayBackground {
    background-color:linear-gradient(90deg, rgba(169,169,169,1) 0%, rgba(255,255,255,1) 100%);
}


.responsive-image {
    width: 100%;
    height: auto;
  }

.how-input {
    flex: 1;
    flex-grow: 2;
}

.how-connector {
    flex: 1;
    flex-shrink: 3;
    font-size: xxx-large;
    transform: translateY(30%);
}

.how-image {
    flex: 1;
    flex-grow:1;
    max-width: 250px;
}

.how-output {
    flex: 1;
    flex-grow:2;
    transform: translateY(35%);
}

.main-input {
    font-size: larger;
    padding-top: 10px;
}

.list-input {
    padding-left: 5%;
}

.list-input:before {
    content:"\A";
    width:8px;
    height:8px;
    border-radius:50%;
    background: #0f53b2;
    display:inline-block;
}

.one-input {
    grid-row: 1;
}

.two-input {
    grid-row: 2;
}

.three-input {
    grid-row: 3;
}

.four-input {
    grid-row: 4;
}

.five-input {
    grid-row: 5;
}

.six-input {
    grid-row: 6;
}

.seven-input {
    grid-row: 7;
}

.eight-input {
    grid-row: 8;
}

.nine-input {
    grid-row: 9;
}

.usecaseTitle {
    padding-top: 20px;
    flex: 1;
    border-color: gray;
    border-width: 2px;
    border-top-style: solid;
    border-bottom-style: solid;
    margin-right: 30px;
    min-width: 200px;
    max-width: 500px;
    margin-top: 20px;
    color: black;
    background-image: linear-gradient(90deg, #F9FAFA , white)
}

.usecaseText {
    flex: 1;
    border-color: gray;
    border-width: 2px;
    margin-right: 30px;
    display: block;
    min-width: 200px;
    max-width: 500px;
}

.usecaseBottom {
    border-color: gray;
    border-width: 2px;
    margin-right: 30px;
    min-width: 200px;
    max-width: 500px;
}

ol li {
    list-style: none;
}

.projectText {
    text-align: left;
    font-size: large;
    font-weight: lighter;
    padding-top: 10px;
}