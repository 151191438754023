.wrapper {
  display: flex;  
  flex-flow: row wrap;
  font-weight: bold;
  text-align: center;
}

.wrapper > * {
  padding: 10px;
  flex: 1 100%;
}

.header {
  background: white !important;
  max-height: 64px;
  min-height: 64px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.headerButton:focus{
  text-decoration: none !important;
  border: 0;
}

.leftSide {
  display: flex;
  flex-grow: 1;
}

.headerContainer {
  margin: auto;
  max-width: 1442px;
}

/* css for small screens */
@media (min-width: 900px) {
  .mobile-view{
    display: none !important;
  }
}

/* css for small screens */
@media (max-width: 901px) {
  .pc-view{
    display: none !important;
  }
}