.contactContent {
    padding-bottom: 50px;
    text-align: center;
}

.about-image {
    width: 90%;
    height: auto;
}

.about_pitch {
    font-size: x-large;
    font-weight:lighter;
    padding-bottom: 60px;
}

@media (max-width: 600px) {
    .about-image {
        width: 100% !important;
    }
}

@media (max-width: 768px) {
    .about_pitch {
        font-size: large !important;
    }
}

.row_cv {
    margin-left: 10% !important;
    margin-right: 10% !important;
}

.openPanel {
    background-color: #0E253A;
    color: #ffffff

}

.closedPanel {
    color: #0E253A
}

.work_item {
    text-align: left;
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 20px;
}

.work_overview {
    font-size:larger;
}

.work_date {
    display: inline;
    font-weight: bolder;
}

.work_position {
    display: inline;
}

.work_company {
    display: inline;
}

.work_location {
    display: inline;
}


.work_summary {
    font-weight: lighter;
    font-size: initial;
}

.course {
    font-weight: lighter;
}

