.wrapper {
  display: flex;  
  flex-flow: row wrap;
  font-weight: bold;
  text-align: center;
}

.wrapper > * {
  padding: 10px;
  flex: 1 100%;
}

.footer {
  background-color: #31323B !important;
  flex-direction: column;
}

.footerContent {
  color: white !important;
}

@media (min-width: 900px) {
  .footer{
    
    flex-direction: row !important;
  }
}