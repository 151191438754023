.App {
  text-align: center;
}

.planContainer {
  justify-content: space-evenly;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  change the original amplify colors for the out of the box UI  */
:root {
  --amplify-primary-color: white;
  --amplify-primary-tint: thistle;
  --amplify-primary-shade: purple;
  --amplify-white: purple;
}


/* classes used by all pages */
.heading1 {
  font-size: 45px;
  font-weight: normal;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  letter-spacing: .2rem;
  padding-top: 8px;
}

.heading2 {
  font-size: 25px;
  font-weight: lighter;
  margin-left: 120px;
  margin-right: 120px;
  margin-top: 30px;
}

.heading3 {
  font-size: x-large;
  font-weight: inherit;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.heading4 {
  font-size: large;
  font-weight: inherit;
  margin-bottom: 40px;
  margin-top: 40px;
}

.text1{
  font-size: large !important;
}

.text2{
  display: none !important;
}

.topSection {
  text-align: center;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 60px;
}

.contentSection {
  padding-bottom: 360px;
}

.inputform {
  padding-left: 30%;
  padding-right: 30%;
  padding-bottom: 20px;
}

@media (max-width: 1000px) {
  .text1{
    font-size:medium;
    display: none !important;
  }

  .text2{
    font-size:medium;
    display: block !important;
  }
}

/* css for small screens */
@media (max-width: 600px) {
  .heading1{
    font-size: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .heading2{
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .contentSection{
    padding-bottom: 80px;
  }

  .inputform{
    padding-left: 10%;
    padding-right: 10%;
  }
}

.accountStatus {
  text-align: center;
  align-items: center;
  padding-right: 40px;
}

.accountActivated {
  color: purple;

}
.accountDeactivated {
  color: gray;
}

.actionButton {
  background-color: purple !important;
  color:white !important;
  border-radius: 5px !important;
  margin: 5px !important;
}

.actionButton:hover {
  background-color: #B395B3 !important;
}

.actionButton:disabled {
  background-color: gray !important;
}

.ghostButton {
  background-color: white !important;
  color:purple !important;
  border-color: purple !important;
  border-radius: 5px !important;
  border-style: solid !important;
  margin: 5px !important;
}

.ghostButton:disabled {
  background-color: gray !important;
  color: white !important;
}

.ghostButton:hover {
  color: #B395B3!important;
}

.raisedButton {
  background-color: white !important;
  color:purple !important;
  border-radius: 5px !important;
  box-shadow: thistle;
  margin: 5px !important;
}

.textButton {
  color:purple !important;
  margin: 5px !important;
}

.textButton:hover {
  background-color: white !important;
  color: #B395B3 !important;
}

.headerButton {
  color: #282c34 !important;
  font-size: larger !important;
}

.headerButton:hover {
  background-color: white !important;
  color: gray !important;
}

.container {
  display: flex; /* or inline-flex */
  align-items: stretch;

}

.buttonExplantion {
  margin-top: 40px;
  margin-bottom: 20px;
}

.actionButtonAnimated {
  color:white !important;
  border-radius: 5px !important;
  margin: 5px !important;
  -webkit-animation: backgroundAnimated 3s ease infinite;
  -moz-animation: backgroundAnimated 3s ease infinite;
  animation: backgroundAnimated 3s ease infinite !important;
}

.leftSideButton {
  margin-right: 220px !important
}

@-webkit-keyframes backgroundAnimated {
  0% {background-color: purple}
  50% {background-color: #b148d2}
  100% {background-color: purple}
}
@-moz-keyframes backgroundAnimated {
  0% {background-color: purple}
  50% {background-color: #b148d2}
  100% {background-color: purple}
}

@keyframes backgroundAnimated {
  0% {background-color: purple}
  50% {background-color: #b148d2}
  100% {background-color: purple}
}

.actionButtonAnimated:hover {
  background-color: #B395B3 !important;
}

.actionButtonAnimated:disabled {
  background-color: gray !important;
}

.flier {
	pointer-events: none;
}

.flier > * {
/* Adjust animation duration to change the element’s speed */
  animation: fly 10s linear infinite;
  pointer-events: none !important;
	top: 400px;
	left: 1800px;
	position: fixed;
	animation-delay: 0s;
	z-index: 999999;
}

@keyframes fly {
  from { top: 400px; left: 1800px; }
  to   { top: 0px; left: 900px; }
}

